export enum EParticipantType {
  OBSERVER = 'observers',
  SIGNER = 'signers',
}

export enum EParticipantKind {
  LEGAL_PERSON = 'legal_person',
  NATURAL_PERSON = 'natural_person',
  OBSERVER = 'observer',
}

export enum EParticipantUpdatePaths {
  ORDER = '/order',
  NAME = '/name',
  EMAIL = '/email',
  TAX_ID = '/taxId',
  EXPIRY = '/expiry',
  COMPANY_NAME = '/companyName',
  COMPANY_TAX_ID = '/companyTaxId',
  ID_DOCUMENT_REQUEST = '/idDocumentRequest',
  SIGNATURE_QUOTE_TEMPLATE_ID = '/signatureQuoteTemplateId',
  APPROVAL_REQUIRED = '/approvalRequired',
  REMINDERDAYS='/reminderDays'
}

export enum ParticipantUpdatePaths {
  ORDERINGTURN = '/order',
  NAME = '/name',
  EMAIL = '/email',
  TAXID = '/taxId',
  EXPIRYAT = '/expiry',
  EXPIRY = '/expiry',
  COMPANYNAME = '/companyName',
  COMPANYTAXID = '/companyTaxId',
  IDDOCUMENTREQUEST = '/idDocumentRequest',
  SIGNATUREQUOTETEMPLATEID = '/signatureQuoteTemplateId',
  APPROVALREQUIRED = '/approvalRequired',
  TOKENID = '/tokenId',
  PHONENUMBER = '/phoneNumber',
  REMINDERDAYS='/reminderDays',
  HANDRECEIVING='/handReceiving',
  SIGNREQUESTVALIDATIONTYPEID = '/signRequestValidationTypeId'
}