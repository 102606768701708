<div nz-row *ngIf="!digital">
  <!--v3-->
  <div nz-col nzMd="24" nzXs="24" nzSm="24" nzLg="24" nzXl="24">
    <nz-descriptions nzBordered nzLayout="vertical">
      <nz-descriptions-item nzTitle="{{ 'SIGNATURES.INFO.NAME' | translate }}">
        {{ viewSignature?.name }}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="{{ 'SIGNATURES.INFO.RFC' | translate }}">
        {{ viewSignature?.rfc }}
      </nz-descriptions-item>
    </nz-descriptions>
  </div>

  <div
    *ngIf="viewSignature?.onBehalfOf.length > 0"
    class="separator-description"
    nz-col
    nzMd="24"
    nzXs="24"
    nzSm="24"
    nzLg="24"
    nzXl="24"
  >
    <div class="custom-legal">
      {{ 'SIGNATURES.INFO.LEGAL' | translate }}
    </div>
    <nz-descriptions nzBordered nzLayout="vertical">
      <nz-descriptions-item
        nzTitle="{{ 'SIGNATURES.INFO.COMPANY' | translate }}"
      >
        {{ viewSignature.onBehalfOf[0].companyName }}
      </nz-descriptions-item>
    </nz-descriptions>
  </div>

  <div
    nz-col
    nzXs="24"
    nzSm="24"
    nzMd="18"
    nzLg="20"
    nzXl="18"
    class="separator-description"
  >
    <nz-descriptions nzTitle="" nzBordered nzLayout="vertical">
      <nz-descriptions-item nzTitle="{{ 'SIGNATURES.INFO.TIME' | translate }}">
        {{
          viewSignature?.signatures[0].pscTimestamp * 1000
            | date : 'dd-MM-yyyy HH:mm a'
        }}
        /
        {{
          viewSignature?.signatures[0].pscTimestamp * 1000
            | date : 'dd-MM-yyyy hh:mm a' : '+0000'
        }}
      </nz-descriptions-item>
    </nz-descriptions>

    <nz-descriptions
      nzTitle=""
      nzBordered
      nzLayout="vertical"
      [nzColumn]="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
    >
      <nz-descriptions-item nzTitle="{{ 'SIGNATURES.INFO.GEO' | translate }}">
        {{ viewSignature?.signatures[0].clientIp }}
      </nz-descriptions-item>
      <nz-descriptions-item
        nzTitle="{{ 'SIGNATURES.INFO.ID.LABEL' | translate }}"
      >
        <div *ngIf="isHaveIdRequest" class="cursor-custom" (click)="showModal()">
          <i class="fal fa-id-card"></i>&nbsp;
          <spa>{{ 'SIGNATURES.INFO.ID.TRUE' | translate }}</spa>
        </div>

        <div *ngIf="!isHaveIdRequest">
          <i class="fal fa-id-card"></i>&nbsp;
          <spa>{{ 'SIGNATURES.INFO.ID.FALSE' | translate }}</spa>
        </div>
      </nz-descriptions-item>
    </nz-descriptions>
  </div>

  <!-- modal -->

  <div
    nz-col
    nzXs="24"
    nzSm="24"
    nzMd="24"
    nzLg="24"
    nzXl="6"
    class="separator-description"
  >
    <nz-descriptions nzTitle="" nzBordered nzLayout="vertical">
      <nz-descriptions-item
        nzTitle=" {{ 'SIGNATURES.INFO.DIGITAL_TRACE' | translate }}"
      >
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
          <div>
            <img
              class="img-ajust"
              [src]="viewSignature?.signatures[0].signatureImageBase64"
              alt="digital trace"
            />
          </div>

          <br />
          <div
            class="img-icon-trace"
            *ngIf="
              document?.signingConfiguration?.signingMethod ===
              signingMethods.ELECTRONIC_SIGNATURE_PLUS
            "
          >
            <i class="far fa-shield-check">
              {{ 'VALIDATOR_UUID.PLUS' | translate }}
            </i>
          </div>
        </div>
      </nz-descriptions-item>
    </nz-descriptions>
  </div>
  <!-- END v3-->
</div>

<!--apartado para digital-->
<div nz-row *ngIf="digital">
  <div nz-col nzMd="24" nzXs="24" nzSm="24" nzLg="24" nzXl="24">
    <nz-descriptions nzBordered nzLayout="vertical">
      <nz-descriptions-item
        nzTitle="{{ 'SIGNATURES.INFO.SERIAL_NUMBER' | translate }}"
      >
        {{ viewSignature?.signatures[0].digitalCertSerialNumber }}
      </nz-descriptions-item>
      <nz-descriptions-item
        nzTitle="{{ 'SIGNATURES.INFO.OCSP_TIMESTAMP' | translate }}"
      >
        {{
          viewSignature?.signatures[0].ocspRequestDate * 1000
            | date : 'dd-MM-yyyy HH:mm:ss a'
        }}
        /
        {{
          viewSignature?.signatures[0].ocspRequestDate * 1000
            | date : 'dd-MM-yyyy hh:mm:ss a' : '+0000'
        }}
      </nz-descriptions-item>
    </nz-descriptions>
  </div>

  <div [hidden]="true" nz-col nzMd="24" nzXs="24" nzSm="12" nzLg="12" nzXl="12">
    <nz-descriptions nzBordered nzLayout="vertical">
      <nz-descriptions-item nzTitle="{{ 'SIGNATURES.INFO.TIME' | translate }}">
        {{ signature?.created_at | date : 'dd-MM-yyyy HH:mm a' }} /
        {{ signature?.created_at }}
      </nz-descriptions-item>
      <nz-descriptions-item nzTitle="{{ 'SIGNATURES.INFO.GEO' | translate }}">
        {{ signature?.client_ip }}
      </nz-descriptions-item>
      <nz-descriptions-item
        nzTitle="{{ 'SIGNATURES.INFO.ID.LABEL' | translate }}"
      >
        <div *ngIf="isHaveIdRequest" class="cursor-custom" (click)="showModal()">
          <i class="fal fa-id-card"></i>&nbsp;
          <spa>{{ 'SIGNATURES.INFO.ID.TRUE' | translate }}</spa>
        </div>

        <div *ngIf="!isHaveIdRequest">
          <i class="fal fa-id-card"></i>&nbsp;
          <spa>{{ 'SIGNATURES.INFO.ID.FALSE' | translate }}</spa>
        </div>
      </nz-descriptions-item>
    </nz-descriptions>
  </div>
</div>

<!-- aplica digital electronica -->
<div nz-col nzMd="24" nzXs="24" nzSm="24" nzLg="24" nzXl="24">
  <hr />
  <div class="title-nom">NOM 151</div>

  <nz-descriptions nzTitle="" nzBordered nzLayout="vertical">
    <nz-descriptions-item
      nzTitle=" {{ 'SIGNATURES.INFO.TIMESTAMP' | translate }}"
    >
      {{
        viewSignature?.signatures[0].pscTimestamp * 1000
          | date : 'dd-MM-yyyy HH:mm:ss a'
      }}
      /
      {{
        viewSignature?.signatures[0].pscTimestamp * 1000
          | date : 'dd-MM-yyyy hh:mm:ss a' : '+0000'
      }}
    </nz-descriptions-item>

    <nz-descriptions-item
      nzTitle=" {{ 'SIGNATURES.INFO.PSC_POLICY' | translate }}"
    >
      {{ viewSignature?.signatures[0].pscPolicy }}
    </nz-descriptions-item>
  </nz-descriptions>
</div>
<!-- end aplica digital electronica -->

<div nz-col nzMd="24" nzXs="24" nzSm="24" nzLg="24" nzXl="24">
  <nz-descriptions nzTitle="" nzBordered nzLayout="vertical">
    <nz-descriptions-item
      nzTitle=" {{ 'SIGNATURES.INFO.FINGER_PRINT' | translate }}"
    >
      {{ viewSignature?.signatures[0].fingerPrint }}
    </nz-descriptions-item>
  </nz-descriptions>
</div>

<div nz-col nzMd="24" nzXs="24" nzSm="24" nzLg="24" nzXl="24">
  <nz-descriptions nzTitle="" nzBordered nzLayout="vertical">
    <nz-descriptions-item
      nzTitle=" {{ 'SIGNATURES.INFO.PSC_SIGNATURE' | translate }}"
    >
      {{ viewSignature?.signatures[0].pscSignature }}
    </nz-descriptions-item>
  </nz-descriptions>
</div>

<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="{{ 'SIGNATURES.INFO.MODAL.TITLE_BIOMETRIC' | translate }}"
  (nzOnCancel)="handleCancel()"
  nzCentered="true"
>
  <ng-container *nzModalContent>
    <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
      <div>
        <img
          class="img-id"
          [src]="viewSignature?.idDocumentRequestInfo.idImageBase64"
          alt="digital trace"
        />
      </div>
    </div>
  </ng-container>
  <div *nzModalFooter>
    <button nz-button nzType="primary" (click)="handleOk()">{{ 'SIGNATURES.INFO.MODAL.ACCEPT' | translate }}</button>
  </div>
</nz-modal>
